<template>
  <mt-popup v-model="popupVisible" class="getCode">
    <mt-header>
      <router-link to slot="left">
        <mt-button @click="popupVisible = false">
          <img src="@/assets/back1.png" height="20" width="20" slot="icon">
        </mt-button>
      </router-link>
    </mt-header>
    <div class="content">
      <div class="item">
        <h2 class="code-verify-tip">
          {{ $t('codeVerifyTip') }}
        </h2>
      </div>
      <div class="item">
        <h4 class="desc">
          {{ $t('codeEnterTip') }}
        </h4>
      </div>
      <div class="item">
        <h4 class="click-text">
          {{ this.phone }}
        </h4>
      </div>
      <div class="item">
        <h4 class="desc">
          {{ $t('clickGetCodeTip') }}
        </h4>
      </div>
      <div class="item">
        <span class="click-text">
          {{ $t('dial')+' *233*45#' }}
        </span>
        <img src="@/assets/copy.png" height="20" width="20" slot="icon" @click="getSmsCode(type)">
      </div>
      <div class="item">
        <mt-field
            class="code"
            :placeholder="$t('codePlaceholder')"
            v-model="code"
            type="text"
            :state="validateCodeState"
        ></mt-field>
      </div>
      <div class="item">
        <mt-button size="large" @click="submit">
          {{ $t("signUp") }}
        </mt-button>
      </div>
      <div class="item" v-show="time>0">
        <span class="desc">
          {{ $t('resendOtpTip') }}
        </span>
        <span class="click-text">
          {{ time+'S' }}
        </span>
      </div>
    </div>
  </mt-popup>
</template>

<script>
import { mapState } from "vuex";
import { validationCode } from '../utils/api'
import { validateCodeRules} from "../utils/rules";
export default {
  name: 'GetCode',
  components: {
  },
  props: {
    phone: String,
    type: String,
  },
  computed: {
    ...mapState(["user", "brand"]),
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.code) : null;
    },
  },
  data () {
    return {
      popupVisible: false,
      isValidateCode: 'Y',
      time: 0,
      timer: null,
      code: '',
      visible: false,
      isCheck:false,
      codeValidateCount: 0
    }
  },
  watch: {
    // 验证码监控
    code(val) {
      if (val.length === 4) {
        // 先不自动提交
        // this.submit()
      }
    },
    popupVisible(val) {
      if (val) {
        this.getSmsCode(this.type)
      } 
    }
  },
  created() {
    
  },
  methods: {
    popup(val) {
      this.popupVisible = val
    },
    validation(list) {
      if (list.indexOf('validateCode') >= 0 && this.validateCodeState != "success") {
        return this.validateCodeState == "warning"
            ? this.$t('validateCodeWarning')
            : this.$t('validateCodeError');
      }
    },
    submit() {
      this.isCheck = true
      if("Y" === this.isValidateCode){
        var error = this.validation(['validateCode']);
        if (error) return this.$toast(error);
      }
      // 验证码校验
      this.$emit('submit', {
        isValidateCode: this.isValidateCode,
        validateCode: "N" === this.isValidateCode ? '0000' : this.code,
      })
    },
    async getSmsCode(type) {
      let phone = this.phone || this.user.phone
      if (!phone) {
        return this.$t("phoneError");
      }
      this.$toast(this.$t('getSmsTips'));
      if (this.time) return
      this.time = this.brand.smsWaitInterval || 60
      this.$axios
        .post(validationCode, {
          phone: phone.replace(/\s/g, ''),
          type: type,
          sendChannel: 'SMS'
        })
        .then((e) => {
          if (e.status.code === "000") {
            if("REGISTER" === type){
              this.codeValidateCount ++
            }
            this.timer = setInterval(() => {
              this.time--
              if (this.time <= 0) {
                this.time = 0
                if (this.codeValidateCount >= this.brand.codeValidatecount) {
                  this.isValidateCode = 'N'
                  this.submit()
                }
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            this.time = 0
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {
          this.time = 0
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.getCode {
  position: relative;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  min-height: 100vh;
  background-image: url('../assets/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  /deep/.mint-header{
    background-color: transparent;
    border-bottom: transparent;
  }
  .forget-password {
    text-align: right;
    color: #855ef4;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
  }
  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    padding-left: 5vw;
    padding-right: 5vw;
    .item {
      position: relative;
      margin-top: 30px;
      text-align: center;
      .mint-button{
        height:55px;
        border-radius: 50px;
        font-weight: 600;
        background: linear-gradient(#BB97FA, #855EF4);
        color: #FFFFFF;
        border:1px solid #FFFFFF;
      }
      .mint-cell {
        background-color: #f3effe;
        border-radius: 50px;
        /deep/ .mint-cell-wrapper {
          background: none;

        }
        /deep/ input {
          background-color: #f3effe;
        }
        /deep/ .mint-field-core {
          background-color: #f3effe;
          text-align: center;
          caret-color: #855ef4;
          font-size: 20px;
        }
        /deep/ input::placeholder{
          color: #999999;
          font-size: 16px;
        }
      }
      .code-verify-tip{
        font-weight: 500;
        font-size: 26pt;
      }
      .desc,.click-text{
        font-weight: 400;
        font-size: 14pt;
      }
      .click-text{
        color: #855EF4;
      }
      .code{
        /deep/ .mint-field-core{
          color: #BB97FA;
        }
      }
    }
  }
}
</style>
<style>
.mint-toast{
  z-index: 4000;
}
</style>