<template>
  <mt-popup
    v-if="visible"
    class="popupPicker"
    v-model="visible"
    style="width: 100%"
    position="bottom">
    <div class="toolbar">
      <button type="button" class="cancel" @click="visible = false">{{ $t('cancel') }}</button>
      <div class="title">{{ title }}</div>
      <button type="button" class="confirm" @click="onConfirm">{{ $t('confirm') }}</button>
    </div>
    <mt-picker :slots="slots" :valueKey="label" @change="onValuesChange"></mt-picker>
  </mt-popup>
</template>

<script>
export default {
  name: 'PopupPicker',
  data() {
    return {
      value: null,
      visible: false,
      title: '',
      label: '',
      options: [],
      // slots: [
      //   {
      //     flex: 1,
      //     values: [
      //       {
      //         key: '1',
      //         value: '2015-01'
      //       },
      //     ],
      //     className: 'slot1',
      //   }
      // ],
    }
  },
  computed: {
    slots() {
      return [
        {
          values: this.options,
          defaultIndex: this.value? this.options.map(e => e[this.label]).indexOf(this.value[this.label]): 0,
        }
      ]
    }
  },
  methods: {
    openPicker(obj) {
      this.label = obj.label
      this.title = obj.title
      this.options = obj.options || []
      this.callback = obj.callback
      this.visible = true
    },
    onValuesChange(picker, values) {
      this.value = values[0]
    },
    onConfirm() {
      this.visible = false
      this.callback && this.callback(this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.popupPicker {
  .toolbar {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 44px;
    .title {
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
    button {
      height: 100%;
      padding: 0 16px;
      font-size: 14px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    .cancel {
      color: #969799;
    }
    .confirm {
      color: #576b95;
    }
  }
}
</style>